
import { Component, Vue } from 'vue-property-decorator'
import CitizenFBOrder from '@/views/visitorInfo/CitizenFBOrder.vue'

@Component({
  name: 'List',
  components: { CitizenFBOrder }
})
export default class List extends Vue {
  private searchInfo = {
    content: '',
    dateRange: [],
    state: ''
  }

  private loading = false
  private tableData = []
  private page = 1
  private size = 10
  private total = 0
  private showDialog = false
  private taskId = ''

  created () {
    this.getData()
  }

  getData () {
    this.loading = true
    const info = this.searchInfo.dateRange ? {
      content: this.searchInfo.content,
      state: this.searchInfo.state,
      startTime: this.searchInfo.dateRange[0] || '',
      endTime: this.searchInfo.dateRange[1] || ''
    } : {
      content: this.searchInfo.content,
      state: this.searchInfo.state
    }
    this.$axios.get(this.$apis.visitor.selectCitizenFeedbackByList, {
      ...info,
      page: this.page,
      size: this.size
    })
      .then((res) => {
        this.total = res.total || 0
        this.tableData = res.list || []
      }).finally(() => {
        this.loading = false
      })
  }

  toPage (name: string, id: string) {
    this.$router.push({ name: name, params: { id } })
  }

  onOrder (row: any) {
    this.showDialog = true
    this.taskId = row.id
  }

  closeDialog (isRefresh: boolean) {
    this.showDialog = false
    if (isRefresh) {
      this.getData()
    }
  }

  onSearch () {
    this.page = 1
    this.getData()
  }
}
