
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { OrderInfo, FeedBackInfo } from '@/types/visitor'
import { ProjectUser } from '@/types/project'

@Component
export default class CitizenFBOrder extends Vue {
  @Prop() readonly taskId!: string;

  private isShow = true
  private info: OrderInfo = {
    planId: '',
    projectId: '',
    orderSubject: '',
    taskDetails: '',
    orderType: '',
    startDate: '',
    endDate: '',
    currentProcessor: '',
    workArea: '',
    longitude: '',
    latitude: '',
    fileList: []
  }

  private date = []

  private rules = {
    projectId: [
      { required: true, message: '请选择项目', trigger: 'change' }
    ],
    orderSubject: [
      { required: true, message: '请输入工单主题', trigger: ['blur', 'change'] }
    ],
    taskDetails: [
      { required: true, message: '请输入工单详情', trigger: ['blur', 'change'] }
    ],
    orderType: [
      { required: true, message: '请选择工单类型', trigger: 'change' }
    ],
    startDate: [
      { required: true, message: '请选择作业日期', trigger: 'change' }
    ],
    workArea: [
      { required: true, message: '请输入作业区域', trigger: ['blur', 'change'] }
    ],
    currentProcessor: [
      { required: true, message: '请选择处理人', trigger: ['blur', 'change'] }
    ]
  }

  private submitShow = false
  private typeList = []
  private projectUserList: Array<ProjectUser> = []
  get projectList () {
    return this.$store.state.projectList
  }

  $refs!: {
    info: ElForm;
  }

  created () {
    this.getTypeList()
    this.getDetail()
  }

  getDetail () {
    this.$axios.get(this.$apis.visitor.selectCitizenFeedbackById, {
      id: this.taskId
    }).then((res: FeedBackInfo) => {
      this.info.planId = res.id
      this.info.taskDetails = res.content
      this.info.workArea = res.woArea
      this.info.longitude = res.longitude
      this.info.latitude = res.latitude
      this.info.fileList = JSON.parse(JSON.stringify(res.resourceList)) || []
    })
  }

  getTypeList () {
    this.$axios.get(this.$apis.newOrder.selectFlowOrderTypeByList).then(res => {
      this.typeList = res.list || []
    })
  }

  // 获取项目下所有人信息
  getProjectUserList () {
    this.$axios.get(this.$apis.project.selectProjectUserByList, {
      projectId: this.info.projectId
    }).then((res: { list: ProjectUser[]; total: number }) => {
      this.projectUserList = res.list || []
    })
  }

  dateChange (date: string[] | null) {
    if (date) {
      this.info.startDate = date[0]
      this.info.endDate = date[1]
    }
  }

  projectChange (value: string) {
    value && this.getProjectUserList()
    this.info.currentProcessor = ''
  }

  beforeClose (done: Function) {
    this.closeDialog()
    done()
  }

  clearInfo () {
    this.$refs.info.resetFields()
    this.$refs.info.clearValidate()
  }

  closeDialog () {
    this.clearInfo()
    this.$emit('closeDialog', false)
  }

  onSubmit () {
    (this.$refs.info as ElForm).validate(valid => {
      if (valid) {
        this.submitShow = true
        this.$axios.post(this.$apis.visitor.placeOrder, this.info).then(() => {
          this.$message.success('下发成功')
          this.clearInfo()
          this.$emit('closeDialog', true)
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }
}
